<template>
  <UiModal
    :limitHeight="limitHeight"
    :title="title"
    :cyTitle="cyTitle"
    :close-others="closeOthers"
    :closeText="closeText"
    class="popup"
    :class="popupClass"
    :wrapperClass="wrapperClass"
    :data-gtm-tracking="gtmTracking"
    :containerClass="containerClass"
    :modal-name="modalName"
    :hideFooter="hideFooter"
    @close="model = false"
  >
    <slot></slot>
  </UiModal>
</template>

<script setup lang="ts">
const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: String,
  popupClass: String,
  limitHeight: Boolean,
  closeOthers: Boolean,
  gtmTracking: String,
  closeText: Boolean,
  wrapperClass: [String, Array],
  containerClass: String,
  cyTitle: String,
  hideFooter: Boolean,
})
const emit = defineEmits(['update:modelValue'])

const model = useVModel(props, 'modelValue', emit)

const { generateModalName, openModal, closeModal } = useModalState()
const modalName = generateModalName()

watch(model, (newValue) => {
  if (newValue) {
    openModal(modalName)
  } else {
    closeModal(modalName)
  }
})

onMounted(() => {
  if (model.value) {
    openModal(modalName)
  }
})
</script>

<style lang="scss" scoped>
::v-deep(.popup) {
  .modal-container,
  .modal-header {
    position: relative;
  }

  .close-btn {
    span {
      margin-right: 10px;
      font-family: 'Nunito Sans Light';
      font-size: 14px;
      letter-spacing: 0.3px;
      line-height: 21px;
    }

    .icon77shop {
      font-size: 12px;
    }
  }
}
</style>
