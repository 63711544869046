<template>
  <Transition :name="modalDirection" @before-enter="beforeEnter" @after-leave="afterLeave">
    <div
      v-show="visible"
      ref="modalMask"
      class="modal-mask"
      :class="[modalDirection, overlayClass]"
      @click.stop="close"
      @touchstart="touchstart"
      @touchmove="touchmove"
      @touchend="touchend"
    >
      <div
        ref="modalWrapper"
        class="modal-wrapper"
        :class="wrapperClass"
        :data-cy="kebabCase(`${cyTitle || title}-modal`)"
        @click.stop=""
      >
        <div
          class="modal-container"
          :class="containerClass"
          :style="bottom && { bottom: `${bottom}px`, transition: 'none' }"
        >
          <button type="button" class="_btn close-btn" data-cy="close-modal-button" @click="close">
            <span v-if="closeText">{{ $t('close', 'Close') }}</span>
            <!-- <img src="/images/shop/icons/ic_nav_cross.svg" alt="close cross" width="18" /> -->
            <i class="icon77 icon77-exit"></i>
          </button>

          <div v-if="title" class="modal-header" :class="headerClass">
            <slot name="header">
              <p v-if="title" class="h1">
                {{ title }}
                <template v-if="!title">&nbsp;</template>
                <slot name="popupInfo"></slot>
              </p>
            </slot>
            <button
              v-if="rightButton"
              type="button"
              class="_btn right-btn"
              :data-gtm-tracking="rightButtonGmt"
              @click="emit('right-button')"
            >
              {{ rightButton }}
            </button>
          </div>
          <slot name="customHeader"></slot>
          <div class="modal-body" :class="bodyClass" :style="modalBodyStyle" @touchstart.stop="">
            <slot></slot>
          </div>
          <div v-if="$slots['footer'] && !hideFooter" class="modal-footer" :class="footerClass">
            <slot name="footer" />
          </div>
        </div>
      </div>
    </div>
  </Transition>
</template>

<script setup lang="ts">
import { UI_MODAL_PROPS } from '~/constants/sharedProps/modal'

const { limitHeight, closeOthers, direction, title } = defineProps({
  ...UI_MODAL_PROPS,
  visible: { type: Boolean, default: false },
})
// TODO: look into how to attach modal to body without style issues, at the moment I use important in tailwind.config to override the styles. Look into it more maybe we can change style or make them important in all cases

const emit = defineEmits(['close', 'right-button'])

const isMoving = ref(false)
const startY = ref(0)
const bottom = ref(0)
const bodyOverflow = ref(false)
const bodyRef = ref<HTMLElement>()

const isLocked = useScrollLock(bodyRef)
const modalWrapper = ref<HTMLElement>()
const modalMask = ref<HTMLElement>()

// Modal state management
const { closeAllModals } = useModalState()

const modalDirection = computed(() => {
  return `modal-${direction}`
})

const close = () => {
  emit('close')
}

const handlePopupHeight = () => {
  const windowHeight = document.documentElement.clientHeight

  if (modalWrapper.value) {
    modalWrapper.value.style.maxHeight = `${windowHeight}px`
  }
  if (modalMask.value) {
    modalMask.value.style.height = '100vh'
  }
}

const beforeEnter = () => {
  if (closeOthers) closeAllModals()

  // TODO: check how it works if noscroll is applied to the body
  if (!isLocked.value) {
    isLocked.value = true
  }
}

const afterLeave = () => {
  if (!bodyOverflow.value || closeOthers) {
    isLocked.value = false
  }
}

const touchstart = (e: TouchEvent) => {
  isMoving.value = true
  startY.value = e.touches[0].clientY
}

const touchmove = (e: TouchEvent) => {
  if (isMoving.value) {
    const layerY = startY.value - e.touches[0].clientY

    bottom.value = Math.min(layerY, 0)
  }
}

const touchend = () => {
  isMoving.value = false

  if (bottom.value < -50) {
    close()
  }

  bottom.value = 0
}

onMounted(() => {
  if (limitHeight) {
    window.addEventListener('resize', handlePopupHeight)
    handlePopupHeight()
  }
  bodyRef.value = document.body
})

onUnmounted(() => {
  window.removeEventListener('resize', handlePopupHeight)
})
</script>

<style lang="scss">
.modal-wrapper {
  max-width: 99.99%; //fix for IE

  &:active {
    &:after {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: transparent;
      z-index: -1;
    }
  }

  .close-btn,
  .right-btn {
    position: absolute;
    z-index: 1;
    top: 8px;
    right: 15px;
    padding: 0 15px;
    margin: 0 -15px;
    line-height: 30px;
  }
}

.modal-header,
.modal-body {
  @media (min-width: 360px) {
  }
}

.modal-header {
  margin-top: 24px;
  margin-bottom: 24px;
}

.modal-body {
  // padding-bottom: 30px;
  position: relative;
}

.modal-footer {
  margin-top: 36px;
  margin-bottom: 16px;
}
</style>

<style lang="scss">
/*@media (max-width: 1023px) {*/
.modal-mask {
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  /*height: 100vh; Fix for centering popup on IE*/
  height: 100%;
  min-height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transition: background-color 0.3s ease;
  overflow: hidden;
  display: flex;

  &.title-center {
    p,
    h1,
    h2,
    h3,
    h4 {
      text-align: center;

      a:hover {
        opacity: 0.4;
      }
    }

    .right-btn {
      right: 15px;
      font-family: 'Nunito Sans Light';
      font-size: 12px;
      letter-spacing: 0.3px;
    }

    .close-cta {
      width: 100%;
    }
  }

  &.strong-overlay {
    background-color: rgba(0, 0, 0, 0.6);
  }

  @media (min-width: 1024px) {
    &.semi-overlay {
      z-index: 499;
    }
  }

  &.modal-right {
    justify-content: flex-end;
  }

  &.modal-center {
    position: fixed;
    justify-content: center;
    align-items: center;

    .modal-header {
      position: relative;
    }
  }

  &.modal-left,
  &.modal-right {
    .modal-wrapper {
      width: 500px;
    }

    .modal-container {
      min-height: 100%;
    }
  }

  &.modal-bottom {
    .modal-wrapper {
      align-self: flex-end;
    }

    .modal-container {
      position: absolute;
      max-height: 100%;
      overflow: auto;

      box-shadow: 0 0 50px 5px rgba(0, 0, 0, 0.5);
      border-radius: 8px 8px 0 0;
    }

    .modal-header {
      position: relative;
    }
  }

  @media (max-width: 440px) {
    ._h1 {
      max-width: 60%;
      margin: auto;
    }

    &.title-center .right-btn {
      margin: 0;
      padding: 0;
      white-space: normal;
      text-align: right;
      width: 25%;
      line-height: 16px;
    }
  }

  @media (max-width: 360px) {
    ._h1 {
      font-size: 20px;
      line-height: 25px;
    }
  }
}

.modal-container {
  position: relative;
  min-width: 300px;
  max-width: 100%;
  max-height: 90vh;
  background-color: #fff;
  transition: all 0.5s ease;
  /*position: absolute;*/
  box-sizing: border-box;
  display: inline-block;
  padding: 0 16px 40px;

  &.-sm {
    max-width: 370px;
  }

  &.-md {
    max-width: 800px;
  }
}

.modal-header {
  background-color: #fff;
}

.modal-enter,
.modal-leave-active {
  background-color: rgba(0, 0, 0, 0) !important;

  &.modal-center .modal-container {
    opacity: 0;
  }

  &.modal-bottom .modal-container {
    transform: translateY(100%);
  }

  &.modal-left .modal-container {
    transform: translateX(-100%);
  }

  &.modal-right .modal-container {
    transform: translateX(100%);
  }
}

// @media (max-width: 1023px) {
.modal-mask {
  position: fixed;
  z-index: 99998;
  bottom: 0;
  min-height: 0;
}

.modal-container {
  bottom: 0;
  width: 100%;
}

@media (max-width: 767px) {
  .modal-wrapper {
    max-width: 100%;
    max-height: 100vh;
    overflow-x: auto;
  }
}
</style>
