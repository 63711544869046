export const UI_MODAL_PROPS = {
  limitHeight: {
    type: Boolean,
    default: false,
  },
  direction: {
    type: String,
    default: 'center',
  },
  rightButton: String,
  rightButtonGmt: String,
  keepOpen: Boolean,
  closeOthers: {
    type: Boolean,
    default: true,
  },
  title: String,
  cyTitle: String,
  overlayClass: String,
  wrapperClass: String,
  onResizeListener: String,
  showHeader: {
    type: Boolean,
    default: true,
  },
  containerClass: String,
  headerClass: String,
  bodyClass: String,
  modalBodyStyle: String,
  footerClass: String,
  closeText: {
    type: Boolean,
    default: false,
  },
  modalName: {
    type: String,
    required: true,
  },
  hideFooter: Boolean,
}
