const modals = ref<string[]>([])
const persistentModals = ref<string[]>([])

function generateModalName() {
  return 'modal_' + crypto.randomUUID()
}

function openModal(name: string, keepOpen = false): void {
  modals.value.push(name)
  if (keepOpen) {
    markModalAsPersistent(name)
  }
}

function markModalAsPersistent(name: string): void {
  persistentModals.value.push(name)
}

function closeModal(name: string): void {
  modals.value = modals.value.filter((modal) => modal !== name)
  unmarkModalAsPersistent(name)
}

function unmarkModalAsPersistent(name: string): void {
  persistentModals.value = persistentModals.value.filter((modal) => modal !== name)
}

function isModalOpen(name: string): boolean {
  return modals.value.includes(name)
}

function getLastModal(): string | undefined {
  return modals.value[modals.value.length - 1]
}

function closeAllModals(): void {
  modals.value = [...persistentModals.value]
}

export function useModalState() {
  return {
    openModal,
    closeModal,
    isModalOpen,
    getLastModal,
    closeAllModals,
    generateModalName,
    markModalAsPersistent,
    unmarkModalAsPersistent,
  }
}
